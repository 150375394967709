* {
  box-sizing: border-box;
}

.containerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  font-family: Roboto;
  width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
}

.blockTitle {
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 50px;
}

.formMainContainer {
  background-color: #202232;
  width: 100%;
  height: 400px;
  border-radius: 40px;
  padding: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 80px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
}

.input {
  width: 565px;
  height: 70px;
  font-size: 36px;
}

.btnReset {
  width: 220px;
  height: 90px;
  background-color: #202232;
  border-radius: 18px;
  border: 0;
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  cursor: pointer;
}

.inputWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.icon {
  width: 45px;
  height: 45px;
  margin: 20px;
  cursor: pointer;
}
.tooltipContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.error {
  width: 500px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 10px;
  color: #ac0505;
}
