.Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
}

.Modal:target {
  display: flex;
  pointer-events: auto;
}
.Modal_Body {
  position: relative;
  z-index: 50;
  top: 50px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 30px 15px;
  background: #FFFFFF;
  border: 4px solid #000000;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 36px;
  line-height: 42px;
  border-radius: 20px;
}
.ModalFull {
  position: absolute;
  display: block;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.successMessage {
  color: #289600;
}

.errorMessage {
  color: #FF0200;
}

.lg {
  width: 80%;
}

.md {
  width: 50%;
}

.Modal_Body[animation="1"] {
  animation: modal-enter 0.9s both;
}

@keyframes modal-enter {
  0% { top: 0; opacity: 0 }
  100% { top: 50px; opacity: 1 }
}
