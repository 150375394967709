@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.mainContainer {
  width: 100%;
  min-height: 100vh;
}
.selectContainer {
  height: 1px;
}
.containerForSelect {
  display: flex;
  align-items: center;
  width: 440px;
  margin-left: 39px;
}
.id {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  margin-right: 17px;
}

.leaderBoardHeader {
  height: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: rgba(0,0,0,0);
}

.median {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 50px;
  text-align: center;
  display: flex;
}

.leaderBoardLink {
  text-decoration: none;
  margin-right: 10px;
  margin-top: 10px;
}

.leaderBoardLink img {
  width: 54px;
}

@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Press+Start+2P&display=swap');
.title {
  display: absolute;
  width: 100%;
  font-family: 'Fredoka One', cursive;
  font-size: 80px;
  line-height: 82px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 50px;
  margin-top: -100px;
}