.listTopNames {
  display: flex;
  width: 100%;
  margin-left: 2px;
  margin-bottom: 10px;
}
.cellLeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.rank {
  width: 25%;
}
.score {
  width: 25%;
}
.name {
  width: 25%;
}
.status{
  width: 25%;
} 
.listItem {
  display: flex;
  height: 119px;
  width: 100%;
  margin-left: 2px;
  background: #fff;
  border-top: 1px solid black;
}
.cellLeader p {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}
.containerScroll {
  width: 100%;
  overflow-y: scroll;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.containerScroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.containerScroll::-webkit-scrollbar-track {
  background: #c4c4c4;
}

/* Handle */
.containerScroll::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.rankState {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #C4C4C4;
  box-shadow: 0px 5px 5px #777;
  background: #C4C4C4;
}

.rankState.online {
  background: #4AD065 !important;
}

.rankState.offline {
  background: #C4C4C4 !important;
}

.noData {
  display: flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  margin-top: 20px;
}