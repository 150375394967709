@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.mainContainer {
  margin-left: 17px;
  margin-top: 56px;
  width: 1504px;
}
.expandLogo {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 5%;
  height:5%;
  right: 10px;
}
.exportLogo {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 5%;
  height:5%;
  right: 10px;
  cursor: pointer;
}
.historyLogo {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 5%;
  height:5%;
  right: 10px;
  cursor: pointer;
}
.selectContainer {
  height: 102px;
}
.id {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  margin-right: 17px;
}
