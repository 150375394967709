@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.mainBoard {
  width: 1447px;
}
.topBar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 127px;
  background-color: #fff;

  border-bottom: 7px solid #1a1b00;
}
.topBar p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  text-align: center;

  color: #000000;
}
.leftBar {
  width: 344px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 100px;
}
.leftBar ul {
  margin-top: 20px;
  box-sizing: content-box;
  margin-left: 10px;
}
.leftBar ul li {
  box-sizing: content-box;
  width: 100%;
  height: 100px;
}
.leaderContainer {
  display: flex;
  background-color: #e5e5e5;
  padding-bottom: 50px;
}
.containerStart {
  width: 100%;
  height: 150%;
  background: linear-gradient(
    108.22deg,
    #f97c08 -2.93%,
    rgba(251, 140, 6, 0.881224) 16.57%,
    rgba(255, 169, 2, 0.66) 52.89%,
    rgba(247, 216, 59, 0.66) 109.53%
  );
}
.containerMenu {
  box-sizing: content-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 40px;
  padding-right: 90px;
}
.containerMenuBoard {
  box-sizing: content-box;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-content: center;
  margin-left: 40px;
  padding-right: 90px;
  position: relative;
}
.topBarMenu {
  position: relative;
}
.containerMenu div {
  width: 500px;
}
.containerMenu a {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  margin-left: 40px;

  color: #000000;
  text-decoration: none;
}
.logo {
  width: 233px;
  margin-left: 40px;
}
.logoUser {
  width: 80px;
}
.containerWebPanel {
  background-color: #f3f3f3;
}
.loginMenu {
  position: absolute;
  width: 176px;
  height: 137px;
  margin-top: 105px;
  margin-left: 0;
  background: #eeeeee;
  box-sizing: border-box;
}

.linkStyle {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
  padding: 0 15px;
}
.selected {
  background: #ff731d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #ffffff;
}
.menuList {
  background: #eeeeee;
  border: 6px solid #1a1b00;
  width: 176px;
  position: absolute;
  margin-top: 105px;
  margin-right: -41px;
}
.menuList li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  /* // */
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  display: flex;

  color: #000000;
}
.menuList li:hover {
  background: #ff7a00;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;

  color: #ffffff;
}

.backButtonLi {
  width: calc(100% - 40px) !important;
  margin: 20px 20px 0 20px !important;
  height: 40px !important;
  margin-bottom: 50px !important;
}

.backButton {
  font-size: 25px;
  border: 1px solid #000000;
  height: 40px;
  justify-content: start;
  white-space: nowrap;
}

.backButton img {
  margin-right: 20px;
  width: 20px;
}

.title {
  display: flex;
  width: 90%;
  font-family: Roboto;
  font-size: 36px;
  line-height: 42px;
  align-items: center;
  text-align: center;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}