.listTopNames {
  display: flex;
  width: 100%;
  background: #ffffff;
  padding-bottom: 10px;
}
.cellLeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.rank {
  margin-right:10%;
  border-radius:50%;
  aspect-ratio: 1 / 1;
  font-weight: 200;
}
.score {
  width: 45%;
}
.name {
  width: 50%;
}
.status{
  width: 5%;
}
.crownIcon{
  width: 90px;
  margin-top: -20px;
}
.listItem {
  display: flex;
  height: 80px;
  width: 95%;
  margin-left: 2.5%;
  border-top: 1px solid black;
  border-radius: 100px;
  margin-top: 20px;
  border: 1px solid black;
  border-width: 5px;
  
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
.cellLeader p {
  font-family: 'Quicksand', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0em;
}
.rank p{
  font-weight: 100;
  font-size: 40px;
}
.containerScroll {
  width: 100%;
  overflow-y: scroll;
}
.containerScroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.containerScroll::-webkit-scrollbar-track {
  background: #c4c4c4;
}

/* Handle */
.containerScroll::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.rankState {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #C4C4C4;
  box-shadow: 0px 5px 5px #777;
  background: #C4C4C4;
  margin-bottom: 5px;
}

.rankState.online {
  background: #4AD065 !important;
}

.rankState.offline {
  background: #C4C4C4 !important;
}

.noData {
  display: flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  margin-top: 20px;
}
