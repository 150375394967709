@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.mainContainer {
  margin-left: 17px;
  margin-top: 79px;
  width: 1326px;
}
.roomList li {
  height: 121px;
  background: #c4ffb6;
  /* width: 1062px; */
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 37px;
}
.roomList li div {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.roomList li div p {
  margin: 4px 0 4px 0;
}
.containerBtn {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-end;
}
.importBtn {
  margin-right: 10px;
  height: 76px;
  width: 345px;
  background: #005696;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;

  color: #ffffff;
}
.containerScroll {
  width: 100%;
  overflow-y: scroll;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.containerScroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.containerScroll::-webkit-scrollbar-track {
  background: #c4c4c4;
}

/* Handle */
.containerScroll::-webkit-scrollbar-thumb {
  background: #ffffff;
}
.containerFlex {
  display: flex;
  justify-content: center;
  width: 100%;
}
