* {
  box-sizing: border-box;
}

.containerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  font-family: Roboto;
  width: 625px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
}

.mainBlock {
  width: 780px;
  height: 420px;
  background-color: #202232;
  border-radius: 40px;
  margin-bottom: 70px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainIcon {
  position: relative;
  width: 426px;
  height: 255px;
  left: 55px;
}

.mainBlockText {
  color: #FFFFFF;
  font-size: 36px;
  font-weight: 500;
}

.btnMainPage {
  width: 220px;
  height: 90px;
  background-color: #202232;
  border-radius: 18px;
  border: 0;
  color: #FFFFFF;
  font-size: 36px;
  font-weight: 700;
  cursor: pointer;
}