.bgContainer {
  box-sizing: content-box;
  width: 100%;
  overflow-y: scroll;
  /* padding-top: 189px; */
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bgContainer::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.bgContainer::-webkit-scrollbar-track {
  background: #c4c4c4;
}

/* Handle */
.bgContainer::-webkit-scrollbar-thumb {
  background: #ffffff;
}
.formMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 170px;
}
.iconUser {
  margin-top: 48px;
  width: 180px;
  height: 180px;
  border-radius: 100%;
  background: #fffbfb;
  opacity: 0.62;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formLoginPage {
  width: 544px;
  height: 87px;
  background: #fcfcfc;
  border-radius: 29px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  padding-left: 20px;
}
.captcha{
  margin: 20px;
  margin-bottom: 50px;
}
.BtnLogin {
  width: 218px;
  height: 87px;

  background: #202232;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;

  color: #ffffff;
}
.BtnLogin_gray {
  width: 218px;
  height: 87px;

  background: #888888;
  box-shadow: 0px 4px 40px rgba(255, 255, 255, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;

  color: #ffffff;
}
.labelInput {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 10px;
  color: #000000;
  margin-left: 15px;
}
.agreementRegion{
  display: inline;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 10px;
  color: #000000;
  margin-left: 0%;
  margin-right: 0%;
  justify-content: left;
  align-items: left;
}
.agreementArea{
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 10px;
  color: #000000;
  justify-content: left;
  align-items: left;
}
.agreementSwitch {
  display: flex;
  width: 100px;
  height: auto;
  justify-content: center;
  margin-right: 20px;
}
.agreementText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
  color: #000000;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 50px;
}
.pOption {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}
.containerSchool {
  display: flex;
  flex-direction: column;
  height: 200px;
}
.containerLoginInput {
  /* margin-top: 10px; */
  position: relative;
  display: flex;
  flex-direction: column;
  height: 220px;
}
.containerTooltip {
  position: absolute;
  left: 540px;
  top: 57px;
}
.listJob {
  height: 230px;
}
.containerPasswordInput {
  height: 260px;
  display: flex;
  flex-direction: column;
}
.BtnBlock {
  display: flex;
  margin-bottom: 50px;
}
.BtnReset {
  width: 218px;
  height: 93.09px;

  background: #202232;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;

  color: #ffffff;
}
.positionRightBtn {
  position: absolute;
  margin-left: 1050px;
  margin-top: 30px;
}
.positionLeftBtn {
  position: absolute;
  margin-right: 1050px;
  margin-top: 30px;
}
.labelListJob {
  margin-bottom: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
  margin-left: 15px;
}
.error {
  width: 500px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 25px;
  margin-left: 40px;
  color: #ac0505;
}
.agreementText{
  font-size: 20px;
  line-height: 20px;
  margin-left: 20px;
}