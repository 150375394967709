* {
  box-sizing: border-box;
}

.containerWrapper {
  display: flex;
  justify-content: center;
}

.container {
  font-family: Roboto;
  width: 625px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
}

.blockTitle {
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 110px;
}

.formMainContainer {
  background-color: #202232;
  width: 100%;
  height: 220px;
  border-radius: 40px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 80px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formTitle {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  margin-top: 15px;
}

.input {
  width: 100%;
  height: 70px;
  font-size: 36px;
}

.btnSendEmail {
  width: 220px;
  height: 90px;
  background-color: #202232;
  border-radius: 18px;
  border: 0;
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  cursor: pointer;
}
.BtnReset {
  width: 218px;
  height: 93.09px;

  background: #202232;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;

  color: #ffffff;
}
.positionRightBtn {
  position: absolute;
  margin-left: 1050px;
  margin-top: 30px;
}

.error {
  width: 500px;
  margin-top: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #ac0505;
}