.labelList {
  width: 400px;
  position: relative;
}
.select {
  z-index: 1;
  width: 249px;
  height: 52px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 26px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #000000;
  padding-left: 40px;
}
.btnList {
  z-index: 5;
  position: absolute;
  border-radius: 50px;
  border: 1px solid black;
  width: 50px;
  height: 50px;
  background: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -52px;
  margin-left: 200px;
}
.btnList img {
  width: 30px;
}

.options {
  z-index: 3;
  position: absolute;
}
.options div {
  display: flex;
  padding-left: 40px;
  align-items: center;
  border: 1px solid black;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  width: 245px;
  height: 52px;
  border-radius: 26px;
  background: #ffffff;
  transition: 0.4s;
}
.options div:hover {
  background: #b9b9b9;
  transition: 0.4s;
}
.formListBig {
  z-index: 5;
  position: absolute;
  border-radius: 20px;
  border: 3px solid black;
  width: 88px;
  height: 88px;
  background: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -87px;
  margin-left: 458px;
}
.formListBig img {
  width: 30px;
}

.optionsB {
  z-index: 3;
  position: absolute;
}
.optionsB div {
  display: flex;
  padding-left: 40px;
  align-items: center;
  border: 1px solid black;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  width: 524px;
  height: 87px;
  border-radius: 26px;
  background: #ffffff;
  transition: 0.4s;
}
.optionsB div:hover {
  background: #b9b9b9;
  transition: 0.4s;
}
.selectB {
  z-index: 1;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 26px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  width: 545px;
  height: 87px;
  color: #000000;
  padding-left: 40px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
}
