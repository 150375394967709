.Modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    background: rgba(0, 0, 0, 0.7);
}
.Modal:target {
    display: flex;
    pointer-events: auto;
}
.Modal_Body {
    position: relative;
    z-index: 200;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    padding: 15px;
    background: #fefefe;
    border: 6px solid #343434;
    box-sizing: border-box;
    border-radius: 15px;
    width: 876px;
    box-sizing: border-box;
    font-size: 2.4em;
}
.ModalFull {
    position: absolute;
    display: block;
    z-index: 90;
    width: 100%;
    height: 100%;
}
.inputRoomName {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 3px;
    height: 52px;
    width: 717px;
}
.blockRemoveRoom input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    padding-left: 15px;
    color: #000000;
}
.content {
    font-family: Roboto;
    font-size: 26px;
    text-align: center;
    margin-bottom: 50px;
}
.blockRemoveRoom p {
    margin-bottom: 20px;
}
.btn_cancel {
    background: #f1f1f1;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    height: 74px;
    width: 218px;
    cursor: pointer;
}
.btn_remove {
    margin-left: 51px;
    margin-right: 31px;
    background: #A10000;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    height: 74px;
    width: 218px;
    cursor: pointer;
}
.btnContainer {
    margin-top: 47px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.inputRoomName {
    margin-bottom: 30px;
}
.title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;

    color: #000000;
    margin-bottom: 50px;
}
