.bgContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.formMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iconUser {
  margin-top: 48px;
  width: 180px;
  height: 180px;
  border-radius: 100%;
  background: #fffbfb;
  opacity: 0.62;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formLoginPage {
  width: 544px;
  height: 87px;
  background: #fcfcfc;
  border-radius: 29px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  padding-left: 20px;
}
.BtnLogin {
  width: 218px;
  height: 87px;

  background: #202232;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;

  color: #ffffff;
}
.labelInput {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 10px;
  color: #000000;
  margin-left: 15px;
}
.containerLoginInput {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  height: 220px;
}
.containerPasswordInput {
  position: relative;
  height: 260px;
  display: flex;
  flex-direction: column;
}
.BtnBlock {
  display: flex;
  justify-content: space-between;
  width: 468px;
  margin-bottom: 58px;
}
.BtnReset {
  background: #202232;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;
  height: 100%;
  color: #ffffff;
}
.BtnResetLink {
  position: absolute;
  margin-left: 1050px;
  margin-top: 60px;
  width: 307px;
  height: 93.09px;
}
.error {
  width: 500px;
  height: 72px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 25px;
  margin-left: 40px;
  color: #ac0505;
}
.containerTooltip {
  position: absolute;
  left: 540px;
  top: 57px;
}
