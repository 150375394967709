@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.namesList div {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
}

.roomName {
  width: 500px;
}

.date {
  min-width: 200px;
}

.time {
  min-width: 330px;
}
.seats {
  min-width: 50px;
}