@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.mainContainer {
  margin-left: 17px;
  margin-top: 24px;
  height: 100%;
  width: 100%;
}
.listPlayers li {
  height: 121px;
  /* width: 1062px; */
  border-radius: 0px;
  border-top: 2px solid black;
  display: flex;
  align-items: center;
}
.containerBtn {
  width: 100%;
  height: 100px;
}
.importBtn {
  margin-right: 90px;
  height: 69px;
  width: 270px;
  background: #005696;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}
.sendBtn {
  margin-right: 90px;
  height: 69px;
  width: 300px;
  background: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

.importBtn label {
  width: 100%;
  height: 69px;
  line-height: 69px;
  vertical-align: middle;
  display: block;
}

.importBtn input {
  display: none;
}

.namesList {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.namesList div {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
}
.email {
  width: 300px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.name {
  width: 300px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.status {
  width: 950px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.invitationCode {
  width: 250px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.remove {
  width: 800px;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  color: #ff0a0a;
  justify-content: center;
}
.link {
  width: 210px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  color: #357900;
}
.containerScroll {
  overflow-y: scroll;
}
.containerScroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.containerScroll::-webkit-scrollbar-track {
  background: #c4c4c4;
}

/* Handle */
.containerScroll::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.containerAddSeat {
  background-color: #ff731d;
  height: 240px;
  width: 100%;
  /* align-items: center; */
}
.AddSeatText {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  margin-top: 17px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}
.inputEmail {
  display: flex;
  align-items: center;
}
.inputEmail label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  margin-right: 19px;
}
.inputEmail input {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 26px;
  background: transparent;
  width: 566px;
  height: 52px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  padding-left: 19px;
  outline: none;
}
.inputClass {
  display: flex;
  align-items: center;
}
.inputClass label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  margin-right: 19px;
}
.inputClass input {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 26px;
  background: transparent;
  width: 164px;
  height: 52px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  padding-left: 19px;
  outline: none;
}

.inputAddContainer {
  display: flex;
  align-items: center;
}
.inputAddContainer label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  margin-right: 19px;
}
.inputAddContainer input {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 26px;
  background: transparent;
  width: 249px;
  height: 52px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  padding-left: 19px;
  outline: none;
}
.boxFormAddSeat {
  display: flex;
  height: 160px;
  width: 710px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: space-around;
}
.boxFormAddSeatInput {
  display: flex;
  /* width: 840px; */
  justify-content: space-between;
}
.BtnAdd {
  width: 227px;
  height: 83px;
  background: #005696;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;

  color: #ffffff;
}
.playersInf {
  width: 100%;
  height: 50px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerForm {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.passwordError {
  margin-left: 100px;
  color: #ff0000;
  font-family: Roboto;
  font-size: 24px;
}