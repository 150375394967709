@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.containerFlex {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mainContainer {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: 35px;
  margin-top: 51px;
  padding-left: 34px;
  padding-top: 9px;
  padding-bottom: 50px;
  width: 974px;
}

.textMain {
  border-bottom: 5px solid #ec1f25;
  color: #000000;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
}
.blockOption {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.blockForDate {
  margin-bottom: 42px;
  margin-left: 68px;
}
.inputContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  padding-bottom: 13px;
  width: 500px;
}
.inputDate {
  border-radius: 26px;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: 52px;
  outline: none;
  padding-left: 24px;
  width: 316px;
}
.IconDate {
  margin-left: 267px;
  margin-top: 2px;
  position: absolute;
  width: 52px;
}
.blockCustomInput {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 324px;
}
.uploadCsv {
  background: rgba(0, 86, 150, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  width: 148.44px;
}
.pOption {
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.gameQuest {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;
  margin-left: 68px;
  margin-top: 24px;
  width: 420px;
}
.miniContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  margin-left: 68px;
  width: 400px;
}
.gameSetting {
  margin-top: 8px;
}
.inputSpeed {
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #000000;
  box-sizing: border-box;
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: 34px;
  line-height: 28px;
  width: 95px;
}
.centerGameSetOption {
  display: flex;
  justify-content: center;
  width: 100px;
}
/* ////// */
.btnChange {
  background: #ffcece;
  border-radius: 6px;
  color: #000000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  height: 47px;
  line-height: 21px;
  width: 107px;
}
.gameType {
  align-items: center;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 132px;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 48px;
  width: 500px;
  width: 630px;
}

.gameType img {
  margin: 0 30px;
}

.gameType p {
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
}
.roomDetails {
  margin-left: 51px;
  margin-top: 18px;
}
.roomDetails div p {
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.roomDetails div ul {
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-left: 40px;
  margin-top: 5px;
}
.roomName {
  align-items: center;
  border: 1px solid #000000;
  box-sizing: border-box;
  height: 52px;
  text-align: center;

  margin-bottom: 12px;
  margin-left: 48px;
  margin-top: 25px;
  width: 575px;
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.roomDescription {
  border: 1px solid #000000;
  box-sizing: border-box;
  margin-bottom: 12px;
  margin-left: 48px;
  resize: vertical;
  max-height: 150px;
  margin-top: 25px;
  padding: 14px;
  width: 575px;
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.saving {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin-left: 5px;
}

.loader {
  margin-left: 30px;
}

.loader img {
  top: 10px;
}