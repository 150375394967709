.containerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: 760px;
  height: 680px;
  background-color: #ffffff;
  padding: 55px;
  margin-top: 30px;
  box-shadow: 1px 5px 5px 1px #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formMainContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 500px;
}

.blockTitle {
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  margin-left: -45px;
}

.labelInput {
  font-size: 18px;
  font-weight: 500;
}

.input {
  width: 243px;
  height: 33px;
  border-radius: 0;
  border: 2px solid #000000;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

input[type="email"]:disabled {
  background: #c4c4c4;
  color: #000000;
  text-decoration: underline;
}

.inputContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnBlock {
  display: flex;
  justify-content: flex-end;
}

.btnSave {
  background-color: #80FF7C;
  width: 85px;
  height: 30px;
  cursor: pointer;
  border: 2px solid #000000;
}

.error {
  position: absolute;
  left: 265px;
  top: 38px;
  color: #ff0000;
}

.passwordError {
  position: absolute;
  left: 0px;
  white-space: nowrap;
  top: 38px;
  color: #ff0000;
}