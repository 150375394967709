@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.leaderList_listTopNames__2SAn7 {
  display: flex;
  width: 100%;
  margin-left: 2px;
  margin-bottom: 10px;
}
.leaderList_cellLeader__205HT {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.leaderList_rank__3yArO {
  width: 25%;
}
.leaderList_score__28N-3 {
  width: 25%;
}
.leaderList_name__3TmPl {
  width: 25%;
}
.leaderList_status__34shf{
  width: 25%;
} 
.leaderList_listItem__1k1cZ {
  display: flex;
  height: 119px;
  width: 100%;
  margin-left: 2px;
  background: #fff;
  border-top: 1px solid black;
}
.leaderList_cellLeader__205HT p {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}
.leaderList_containerScroll__1d_79 {
  width: 100%;
  overflow-y: scroll;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.leaderList_containerScroll__1d_79::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.leaderList_containerScroll__1d_79::-webkit-scrollbar-track {
  background: #c4c4c4;
}

/* Handle */
.leaderList_containerScroll__1d_79::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.leaderList_rankState__1HgB4 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #C4C4C4;
  box-shadow: 0px 5px 5px #777;
  background: #C4C4C4;
}

.leaderList_rankState__1HgB4.leaderList_online__2hCoj {
  background: #4AD065 !important;
}

.leaderList_rankState__1HgB4.leaderList_offline__3izEr {
  background: #C4C4C4 !important;
}

.leaderList_noData__133_G {
  display: flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  margin-top: 20px;
}
.leaderBoard_mainContainer__2OQZv {
  margin-left: 17px;
  margin-top: 56px;
  width: 1504px;
}
.leaderBoard_expandLogo__3vjtY {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 5%;
  height:5%;
  right: 10px;
}
.leaderBoard_exportLogo__2LTXE {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 5%;
  height:5%;
  right: 10px;
  cursor: pointer;
}
.leaderBoard_historyLogo__3hEhX {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 5%;
  height:5%;
  right: 10px;
  cursor: pointer;
}
.leaderBoard_selectContainer__2ahsK {
  height: 102px;
}
.leaderBoard_id__jnXcG {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  margin-right: 17px;
}

.MenuRoom_labelList__2_Xub {
  width: 400px;
  position: relative;
}
.MenuRoom_select__1k1F5 {
  z-index: 1;
  width: 249px;
  height: 52px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 26px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #000000;
  padding-left: 40px;
}
.MenuRoom_btnList__B8x8z {
  z-index: 5;
  position: absolute;
  border-radius: 50px;
  border: 1px solid black;
  width: 50px;
  height: 50px;
  background: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -52px;
  margin-left: 200px;
}
.MenuRoom_btnList__B8x8z img {
  width: 30px;
}

.MenuRoom_options__3a8HB {
  z-index: 3;
  position: absolute;
}
.MenuRoom_options__3a8HB div {
  display: flex;
  padding-left: 40px;
  align-items: center;
  border: 1px solid black;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  width: 245px;
  height: 52px;
  border-radius: 26px;
  background: #ffffff;
  transition: 0.4s;
}
.MenuRoom_options__3a8HB div:hover {
  background: #b9b9b9;
  transition: 0.4s;
}
.MenuRoom_formListBig__1jqpt {
  z-index: 5;
  position: absolute;
  border-radius: 20px;
  border: 3px solid black;
  width: 88px;
  height: 88px;
  background: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -87px;
  margin-left: 458px;
}
.MenuRoom_formListBig__1jqpt img {
  width: 30px;
}

.MenuRoom_optionsB__E7XKC {
  z-index: 3;
  position: absolute;
}
.MenuRoom_optionsB__E7XKC div {
  display: flex;
  padding-left: 40px;
  align-items: center;
  border: 1px solid black;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  width: 524px;
  height: 87px;
  border-radius: 26px;
  background: #ffffff;
  transition: 0.4s;
}
.MenuRoom_optionsB__E7XKC div:hover {
  background: #b9b9b9;
  transition: 0.4s;
}
.MenuRoom_selectB__3LUsW {
  z-index: 1;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 26px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  width: 545px;
  height: 87px;
  color: #000000;
  padding-left: 40px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
}

.layout_mainBoard__3OAPK {
  width: 1447px;
}
.layout_topBar__bZpdf {
  display: flex;
  align-items: center;
  width: 100%;
  height: 127px;
  background-color: #fff;

  border-bottom: 7px solid #1a1b00;
}
.layout_topBar__bZpdf p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  text-align: center;

  color: #000000;
}
.layout_leftBar__9gX0z {
  width: 344px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 100px;
}
.layout_leftBar__9gX0z ul {
  margin-top: 20px;
  box-sizing: content-box;
  margin-left: 10px;
}
.layout_leftBar__9gX0z ul li {
  box-sizing: content-box;
  width: 100%;
  height: 100px;
}
.layout_leaderContainer__pHoXt {
  display: flex;
  background-color: #e5e5e5;
  padding-bottom: 50px;
}
.layout_containerStart__3Enzq {
  width: 100%;
  height: 150%;
  background: linear-gradient(
    108.22deg,
    #f97c08 -2.93%,
    rgba(251, 140, 6, 0.881224) 16.57%,
    rgba(255, 169, 2, 0.66) 52.89%,
    rgba(247, 216, 59, 0.66) 109.53%
  );
}
.layout_containerMenu__2q6ER {
  box-sizing: content-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 40px;
  padding-right: 90px;
}
.layout_containerMenuBoard__1plT0 {
  box-sizing: content-box;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-content: center;
  margin-left: 40px;
  padding-right: 90px;
  position: relative;
}
.layout_topBarMenu__1hETf {
  position: relative;
}
.layout_containerMenu__2q6ER div {
  width: 500px;
}
.layout_containerMenu__2q6ER a {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  margin-left: 40px;

  color: #000000;
  text-decoration: none;
}
.layout_logo__1qO2D {
  width: 233px;
  margin-left: 40px;
}
.layout_logoUser__2Jizf {
  width: 80px;
}
.layout_containerWebPanel__WnrQ8 {
  background-color: #f3f3f3;
}
.layout_loginMenu__2zCJt {
  position: absolute;
  width: 176px;
  height: 137px;
  margin-top: 105px;
  margin-left: 0;
  background: #eeeeee;
  box-sizing: border-box;
}

.layout_linkStyle__3lkmD {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
  padding: 0 15px;
}
.layout_selected__382mq {
  background: #ff731d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #ffffff;
}
.layout_menuList__3_K8- {
  background: #eeeeee;
  border: 6px solid #1a1b00;
  width: 176px;
  position: absolute;
  margin-top: 105px;
  margin-right: -41px;
}
.layout_menuList__3_K8- li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  /* // */
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  display: flex;

  color: #000000;
}
.layout_menuList__3_K8- li:hover {
  background: #ff7a00;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;

  color: #ffffff;
}

.layout_backButtonLi__2nnBm {
  width: calc(100% - 40px) !important;
  margin: 20px 20px 0 20px !important;
  height: 40px !important;
  margin-bottom: 50px !important;
}

.layout_backButton__c8Zb3 {
  font-size: 25px;
  border: 1px solid #000000;
  height: 40px;
  justify-content: start;
  white-space: nowrap;
}

.layout_backButton__c8Zb3 img {
  margin-right: 20px;
  width: 20px;
}

.layout_title__19OAE {
  display: flex;
  width: 90%;
  font-family: Roboto;
  font-size: 36px;
  line-height: 42px;
  align-items: center;
  text-align: center;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.leaderList_listTopNames__3E8AL {
  display: flex;
  width: 100%;
  background: #ffffff;
  padding-bottom: 10px;
}
.leaderList_cellLeader__19qs4 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.leaderList_rank__DSgpJ {
  margin-right:10%;
  border-radius:50%;
  aspect-ratio: 1 / 1;
  font-weight: 200;
}
.leaderList_score__VQS-e {
  width: 45%;
}
.leaderList_name__ofF9I {
  width: 50%;
}
.leaderList_status__T8ctd{
  width: 5%;
}
.leaderList_crownIcon__2D73F{
  width: 90px;
  margin-top: -20px;
}
.leaderList_listItem__31H8A {
  display: flex;
  height: 80px;
  width: 95%;
  margin-left: 2.5%;
  border-top: 1px solid black;
  border-radius: 100px;
  margin-top: 20px;
  border: 1px solid black;
  border-width: 5px;
  
}
.leaderList_cellLeader__19qs4 p {
  font-family: 'Quicksand', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0em;
}
.leaderList_rank__DSgpJ p{
  font-weight: 100;
  font-size: 40px;
}
.leaderList_containerScroll__-To0n {
  width: 100%;
  overflow-y: scroll;
}
.leaderList_containerScroll__-To0n::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.leaderList_containerScroll__-To0n::-webkit-scrollbar-track {
  background: #c4c4c4;
}

/* Handle */
.leaderList_containerScroll__-To0n::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.leaderList_rankState__1LPmv {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #C4C4C4;
  box-shadow: 0px 5px 5px #777;
  background: #C4C4C4;
  margin-bottom: 5px;
}

.leaderList_rankState__1LPmv.leaderList_online__VXggD {
  background: #4AD065 !important;
}

.leaderList_rankState__1LPmv.leaderList_offline__3rsl5 {
  background: #C4C4C4 !important;
}

.leaderList_noData__1AzhI {
  display: flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  margin-top: 20px;
}

.leaderBoard_mainContainer__1PFBt {
  width: 100%;
  min-height: 100vh;
}
.leaderBoard_selectContainer__2c2Aa {
  height: 1px;
}
.leaderBoard_containerForSelect__2Kzj0 {
  display: flex;
  align-items: center;
  width: 440px;
  margin-left: 39px;
}
.leaderBoard_id__3XKh7 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  margin-right: 17px;
}

.leaderBoard_leaderBoardHeader__1YpDG {
  height: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: rgba(0,0,0,0);
}

.leaderBoard_median__2nLV6 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 50px;
  text-align: center;
  display: flex;
}

.leaderBoard_leaderBoardLink__1gVGU {
  text-decoration: none;
  margin-right: 10px;
  margin-top: 10px;
}

.leaderBoard_leaderBoardLink__1gVGU img {
  width: 54px;
}
.leaderBoard_title__2g8Dk {
  display: absolute;
  width: 100%;
  font-family: 'Fredoka One', cursive;
  font-size: 80px;
  line-height: 82px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 50px;
  margin-top: -100px;
}
.Login_bgContainer__3WWts {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Login_formMainContainer__1u2cr {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Login_iconUser__z3F7N {
  margin-top: 48px;
  width: 180px;
  height: 180px;
  border-radius: 100%;
  background: #fffbfb;
  opacity: 0.62;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Login_formLoginPage__3yCdk {
  width: 544px;
  height: 87px;
  background: #fcfcfc;
  border-radius: 29px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  padding-left: 20px;
}
.Login_BtnLogin__2MMZ9 {
  width: 218px;
  height: 87px;

  background: #202232;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;

  color: #ffffff;
}
.Login_labelInput__B7_Xv {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 10px;
  color: #000000;
  margin-left: 15px;
}
.Login_containerLoginInput__33w4z {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  height: 220px;
}
.Login_containerPasswordInput__1l_KQ {
  position: relative;
  height: 260px;
  display: flex;
  flex-direction: column;
}
.Login_BtnBlock__3zVEG {
  display: flex;
  justify-content: space-between;
  width: 468px;
  margin-bottom: 58px;
}
.Login_BtnReset__1MO-B {
  background: #202232;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;
  height: 100%;
  color: #ffffff;
}
.Login_BtnResetLink__3V2kq {
  position: absolute;
  margin-left: 1050px;
  margin-top: 60px;
  width: 307px;
  height: 93.09px;
}
.Login_error__ZT5Dm {
  width: 500px;
  height: 72px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 25px;
  margin-left: 40px;
  color: #ac0505;
}
.Login_containerTooltip__1N6mo {
  position: absolute;
  left: 540px;
  top: 57px;
}

.Tooltip_block__211s1 {
  pointer-events: none;
  position: absolute;
  height: 188px;
  width: 352px;
  padding: 12px;
  background: #ffffff;
  border: 4px solid #000000;
  box-sizing: border-box;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 33px;
  margin-top: -24px;
  margin-left: 34px;
  color: #000000;
}
.Tooltip_logo__Ew8b4 {
  position: relative;
  border-radius: 50px;
  width: 73px;
  height: 73px;
}
.Tooltip_isLightLogo__2-muV {
  width: 39px;
  height: 39px;
}
.Tooltip_isLightPosition__2FHSq {
  margin-top: -20px;
  margin-left: 20px;
}
.Tooltip_mainContainer__3ydOP {
  position: relative;
  margin-left: 20px;
}

.Register_bgContainer__377te {
  box-sizing: content-box;
  width: 100%;
  overflow-y: scroll;
  /* padding-top: 189px; */
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Register_bgContainer__377te::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.Register_bgContainer__377te::-webkit-scrollbar-track {
  background: #c4c4c4;
}

/* Handle */
.Register_bgContainer__377te::-webkit-scrollbar-thumb {
  background: #ffffff;
}
.Register_formMainContainer__3giUC {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 170px;
}
.Register_iconUser__2cwJj {
  margin-top: 48px;
  width: 180px;
  height: 180px;
  border-radius: 100%;
  background: #fffbfb;
  opacity: 0.62;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Register_formLoginPage__2dXfH {
  width: 544px;
  height: 87px;
  background: #fcfcfc;
  border-radius: 29px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  padding-left: 20px;
}
.Register_captcha__Xs-Te{
  margin: 20px;
  margin-bottom: 50px;
}
.Register_BtnLogin__lK2_s {
  width: 218px;
  height: 87px;

  background: #202232;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;

  color: #ffffff;
}
.Register_BtnLogin_gray__ocq7t {
  width: 218px;
  height: 87px;

  background: #888888;
  box-shadow: 0px 4px 40px rgba(255, 255, 255, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;

  color: #ffffff;
}
.Register_labelInput__1VsOF {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 10px;
  color: #000000;
  margin-left: 15px;
}
.Register_agreementRegion__1zWzg{
  display: inline;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 10px;
  color: #000000;
  margin-left: 0%;
  margin-right: 0%;
  justify-content: left;
  align-items: left;
}
.Register_agreementArea__2WbVl{
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 10px;
  color: #000000;
  justify-content: left;
  align-items: left;
}
.Register_agreementSwitch__3gi5j {
  display: flex;
  width: 100px;
  height: auto;
  justify-content: center;
  margin-right: 20px;
}
.Register_agreementText__16tjN {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
  color: #000000;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 50px;
}
.Register_pOption__33x9I {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}
.Register_containerSchool__2-UZP {
  display: flex;
  flex-direction: column;
  height: 200px;
}
.Register_containerLoginInput__3jI1P {
  /* margin-top: 10px; */
  position: relative;
  display: flex;
  flex-direction: column;
  height: 220px;
}
.Register_containerTooltip__119CI {
  position: absolute;
  left: 540px;
  top: 57px;
}
.Register_listJob__1KFGn {
  height: 230px;
}
.Register_containerPasswordInput__2kX6T {
  height: 260px;
  display: flex;
  flex-direction: column;
}
.Register_BtnBlock__3SSf9 {
  display: flex;
  margin-bottom: 50px;
}
.Register_BtnReset__2b-Go {
  width: 218px;
  height: 93.09px;

  background: #202232;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;

  color: #ffffff;
}
.Register_positionRightBtn__1qrfw {
  position: absolute;
  margin-left: 1050px;
  margin-top: 30px;
}
.Register_positionLeftBtn__1aewA {
  position: absolute;
  margin-right: 1050px;
  margin-top: 30px;
}
.Register_labelListJob__1D8_R {
  margin-bottom: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
  margin-left: 15px;
}
.Register_error__2vrbH {
  width: 500px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 25px;
  margin-left: 40px;
  color: #ac0505;
}
.Register_agreementText__16tjN{
  font-size: 20px;
  line-height: 20px;
  margin-left: 20px;
}
.Modal_Modal__1koKG {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
}
.Modal_Modal__1koKG:target {
  display: flex;
  pointer-events: auto;
}
.Modal_Modal_Body__2PwMu {
  position: relative;
  z-index: 50;
  top: 50px;
  width: 400px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 15px;
  background: rgba(255, 255, 255, 0.9);
  border: 4px solid #8b8b8b;
  box-sizing: border-box;
  font-size: 2.4em;
}
.Modal_ModalFull__2N3te {
  position: absolute;
  display: block;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.Switch_switchOption__13Y1F {
  width: 52px;
  height: 28px;

  background: #c4c4c4;
  border-radius: 27.5px;
}
.Switch_switchBlockOff__dUnbV {
  width: 35px;
  height: 28px;
  background: #000000;
  border-radius: 27.5px;
  transition: 0.2s;
}
.Switch_switchBlockOn__3LoWp {
  background: #ff731d;
  width: 35px;
  height: 28px;
  border-radius: 27.5px;
  -webkit-transform: translateX(17px);
          transform: translateX(17px);
  transition: 0.2s;
}

.Players_mainContainer__3bjre {
  margin-left: 17px;
  margin-top: 24px;
  height: 100%;
  width: 100%;
}
.Players_listPlayers__4GirD li {
  height: 121px;
  /* width: 1062px; */
  border-radius: 0px;
  border-top: 2px solid black;
  display: flex;
  align-items: center;
}
.Players_containerBtn__2o_-N {
  width: 100%;
  height: 100px;
}
.Players_importBtn__ojdGY {
  margin-right: 90px;
  height: 69px;
  width: 270px;
  background: #005696;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}
.Players_sendBtn__32bE_ {
  margin-right: 90px;
  height: 69px;
  width: 300px;
  background: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

.Players_importBtn__ojdGY label {
  width: 100%;
  height: 69px;
  line-height: 69px;
  vertical-align: middle;
  display: block;
}

.Players_importBtn__ojdGY input {
  display: none;
}

.Players_namesList__3mMVv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.Players_namesList__3mMVv div {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
}
.Players_email__z_Ez5 {
  width: 300px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.Players_name__u1MGM {
  width: 300px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.Players_status__D_IzU {
  width: 950px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.Players_invitationCode__3agBe {
  width: 250px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.Players_remove__2JbHA {
  width: 800px;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  color: #ff0a0a;
  justify-content: center;
}
.Players_link__OZILT {
  width: 210px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  color: #357900;
}
.Players_containerScroll__3rIU_ {
  overflow-y: scroll;
}
.Players_containerScroll__3rIU_::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.Players_containerScroll__3rIU_::-webkit-scrollbar-track {
  background: #c4c4c4;
}

/* Handle */
.Players_containerScroll__3rIU_::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.Players_containerAddSeat__2EE-P {
  background-color: #ff731d;
  height: 240px;
  width: 100%;
  /* align-items: center; */
}
.Players_AddSeatText__F1Nms {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  margin-top: 17px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}
.Players_inputEmail__1JTX5 {
  display: flex;
  align-items: center;
}
.Players_inputEmail__1JTX5 label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  margin-right: 19px;
}
.Players_inputEmail__1JTX5 input {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 26px;
  background: transparent;
  width: 566px;
  height: 52px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  padding-left: 19px;
  outline: none;
}
.Players_inputClass__d4w51 {
  display: flex;
  align-items: center;
}
.Players_inputClass__d4w51 label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  margin-right: 19px;
}
.Players_inputClass__d4w51 input {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 26px;
  background: transparent;
  width: 164px;
  height: 52px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  padding-left: 19px;
  outline: none;
}

.Players_inputAddContainer__2AQIs {
  display: flex;
  align-items: center;
}
.Players_inputAddContainer__2AQIs label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  margin-right: 19px;
}
.Players_inputAddContainer__2AQIs input {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 26px;
  background: transparent;
  width: 249px;
  height: 52px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  padding-left: 19px;
  outline: none;
}
.Players_boxFormAddSeat__1yuNn {
  display: flex;
  height: 160px;
  width: 710px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: space-around;
}
.Players_boxFormAddSeatInput__2tkMs {
  display: flex;
  /* width: 840px; */
  justify-content: space-between;
}
.Players_BtnAdd__13ykf {
  width: 227px;
  height: 83px;
  background: #005696;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;

  color: #ffffff;
}
.Players_playersInf__1IJFB {
  width: 100%;
  height: 50px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Players_containerForm__2rYwb {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Players_passwordError__GJBI5 {
  margin-left: 100px;
  color: #ff0000;
  font-family: Roboto;
  font-size: 24px;
}
.PlayersItem_namesList__1p6W9 div {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
}
.PlayersItem_email__2rFrm {
  width: 500px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.PlayersItem_name__30mEk {
  width: 300px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.PlayersItem_status__13tqg {
  width: 950px;
  font-size: 36px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  text-transform: capitalize;
}
.PlayersItem_statusWaiting__2TKxa {
  color: rgba(0, 170, 139, 1);
}
.PlayersItem_statusPlaying__wj6Nw {
  color: rgba(39, 150, 0, 1);
}
.PlayersItem_statusOffline__1uNPH {
  color: rgba(138, 0, 0, 1);
}
.PlayersItem_invitationCode__1ejvV {
  width: 260px;
  font-size: 36px;
  display: flex;
  justify-content: center;
}
.PlayersItem_remove__3gCzB {
  width: 400px;
  margin-left: 200px;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  color: #ff0a0a;
  justify-content: center;
}
.PlayersItem_remove__3gCzB img {
  cursor: pointer;
}
.PlayersItem_link__33dFP {
  width: 210px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  color: #357900;
}
.PlayersItem_link__33dFP img {
  cursor: pointer;
}
.PlayersItem_tooltip__1Xw6p {
  padding-top: 38px;
  padding-left: 24px;
  position: absolute;
  border-radius: 4px;
  width: 525px;
  height: 278px;
  background: rgba(255, 255, 255, 0.9);
  border: 4px solid #8b8b8b;
  box-sizing: border-box;
}
.PlayersItem_tooltip__1Xw6p div p {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;

  color: #000000;
}

.PlayersItem_tooltipStatus__1b11M {
  text-transform: capitalize;
}

.PlayersItem_image__108Vi {
  min-width: 150px;
}

.PlayersItem_roomName__R5PY4 {
  min-width: 200px;
}

.PlayersItem_date__jdCUv {
  min-width: 200px;
}

.PlayersItem_time__1DV49 {
  min-width: 330px;
}
.Rooms_mainContainer__1AVUf {
  margin-left: 17px;
  margin-top: 79px;
  width: 1326px;
}
.Rooms_roomList__2LNQE li {
  height: 121px;
  background: #c4ffb6;
  /* width: 1062px; */
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 37px;
}
.Rooms_roomList__2LNQE li div {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Rooms_roomList__2LNQE li div p {
  margin: 4px 0 4px 0;
}
.Rooms_containerBtn__2oBKo {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-end;
}
.Rooms_importBtn__TtI0V {
  margin-right: 10px;
  height: 76px;
  width: 345px;
  background: #005696;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;

  color: #ffffff;
}
.Rooms_containerScroll__36nJq {
  width: 100%;
  overflow-y: scroll;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.Rooms_containerScroll__36nJq::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.Rooms_containerScroll__36nJq::-webkit-scrollbar-track {
  background: #c4c4c4;
}

/* Handle */
.Rooms_containerScroll__36nJq::-webkit-scrollbar-thumb {
  background: #ffffff;
}
.Rooms_containerFlex__32J9G {
  display: flex;
  justify-content: center;
  width: 100%;
}

.RoomsItem_namesList__jFbCl div {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
}

.RoomsItem_roomName__1Yk_b {
  width: 500px;
}

.RoomsItem_date__2h0dZ {
  min-width: 200px;
}

.RoomsItem_time__3RTR6 {
  min-width: 330px;
}
.RoomsItem_seats__1je-9 {
  min-width: 50px;
}
.RemoveRoomConfirm_Modal__WZoLo {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    background: rgba(0, 0, 0, 0.7);
}
.RemoveRoomConfirm_Modal__WZoLo:target {
    display: flex;
    pointer-events: auto;
}
.RemoveRoomConfirm_Modal_Body__EN6Kg {
    position: relative;
    z-index: 200;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    padding: 15px;
    background: #fefefe;
    border: 6px solid #343434;
    box-sizing: border-box;
    border-radius: 15px;
    width: 876px;
    box-sizing: border-box;
    font-size: 2.4em;
}
.RemoveRoomConfirm_ModalFull__2pOVm {
    position: absolute;
    display: block;
    z-index: 90;
    width: 100%;
    height: 100%;
}
.RemoveRoomConfirm_inputRoomName__2T7Tv {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 3px;
    height: 52px;
    width: 717px;
}
.RemoveRoomConfirm_blockRemoveRoom__18GFJ input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    padding-left: 15px;
    color: #000000;
}
.RemoveRoomConfirm_content__26HJd {
    font-family: Roboto;
    font-size: 26px;
    text-align: center;
    margin-bottom: 50px;
}
.RemoveRoomConfirm_blockRemoveRoom__18GFJ p {
    margin-bottom: 20px;
}
.RemoveRoomConfirm_btn_cancel__3HRzy {
    background: #f1f1f1;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    height: 74px;
    width: 218px;
    cursor: pointer;
}
.RemoveRoomConfirm_btn_remove__1zvNS {
    margin-left: 51px;
    margin-right: 31px;
    background: #A10000;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    height: 74px;
    width: 218px;
    cursor: pointer;
}
.RemoveRoomConfirm_btnContainer__3yOy3 {
    margin-top: 47px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.RemoveRoomConfirm_inputRoomName__2T7Tv {
    margin-bottom: 30px;
}
.RemoveRoomConfirm_title__3c67p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;

    color: #000000;
    margin-bottom: 50px;
}

.MenuRoom_labelList__2Qm_g {
  width: 400px;
  position: relative;
}
.MenuRoom_select__3r8be {
  z-index: 1;
  height: 52px;
  width: 717px;
  border-radius: 3px;
  background: #ffffff;
  box-sizing: border-box;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #000000;
  padding-left: 40px;
}
.MenuRoom_btnList__1YYOm {
  z-index: 5;
  position: absolute;
  border: 4px solid black;
  width: 51px;
  height: 51px;
  background: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -51px;
  margin-left: 665px;
}
.MenuRoom_btnList__1YYOm img {
  width: 30px;
}

.MenuRoom_options__2Kjv3 {
  z-index: 3;
  position: absolute;
}
.MenuRoom_options__2Kjv3 div {
  display: flex;
  padding-left: 40px;
  align-items: center;
  border: 1px solid black;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  height: 52px;
  width: 717px;
  border-radius: 3px;
  background: #ffffff;
  transition: 0.4s;
}
.MenuRoom_options__2Kjv3 div:hover {
  background: #b9b9b9;
  transition: 0.4s;
}

.NewRoom_Modal__3ileH {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  background: rgba(0, 0, 0, 0.7);
}
.NewRoom_Modal__3ileH:target {
  display: flex;
  pointer-events: auto;
}
.NewRoom_Modal_Body__Bmm1W {
  position: relative;
  z-index: 200;
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding: 15px;
  background: #fefefe;
  border: 6px solid #343434;
  box-sizing: border-box;
  border-radius: 15px;
  width: 876px;
  box-sizing: border-box;
  font-size: 2.4em;
}
.NewRoom_ModalFull__U-KfQ {
  position: absolute;
  display: block;
  z-index: 90;
  width: 100%;
  height: 100%;
}
.NewRoom_inputRoomName__3IEg6 {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  height: 52px;
  width: 717px;
}
.NewRoom_blockNewRoom__14RC3 input {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  padding-left: 15px;

  color: #000000;
}
.NewRoom_blockNewRoom__14RC3 p {
  margin-bottom: 20px;
}
.NewRoom_btn_cancel__26mz2 {
  background: #f1f1f1;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  height: 74px;
  width: 218px;
}
.NewRoom_btn_create__1iOAo {
  margin-left: 51px;
  margin-right: 31px;
  background: #005696;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  height: 74px;
  width: 218px;
}
.NewRoom_btnContainer__1Yglv {
  margin-top: 47px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.NewRoom_inputRoomName__3IEg6 {
  margin-bottom: 30px;
}
.NewRoom_title__3Q_K4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;

  color: #000000;
  margin-bottom: 50px;
}

.NewRoom_errorMessage__1Bo0Y {
  color: #ff0000;
  font-family: Roboto;
  font-size: 24px;
  margin-bottom: 30px;
}

.RoomOption_containerFlex__yCgsg {
  display: flex;
  justify-content: center;
  width: 100%;
}
.RoomOption_mainContainer__14S_l {
  display: flex;
  flex-direction: column;
  margin-left: 35px;
  margin-top: 51px;
  padding-top: 9px;
  width: 974px;
  height: 700px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.RoomOption_textMain__3o9yV {
  border-bottom: 5px solid #ec1f25;
  margin-left: 34px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;

  color: #000000;
}
.RoomOption_blockOption__19Frr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.RoomOption_blockForDate__2yasG {
  margin-bottom: 42px;
  margin-left: 68px;
}
.RoomOption_inputContainer__2Bf2Q {
  margin-top: 11px;
  padding-bottom: 13px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.RoomOption_inputDate__2WkGo {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 26px;
  height: 52px;
  width: 316px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  padding-left: 24px;
  font-size: 24px;
  box-sizing: border-box;
}
.RoomOption_IconDate__Gej23 {
  /* display: none; */
  width: 52px;
  position: absolute;
  margin-left: 267px;
  margin-top: 2px;
}
.RoomOption_blockCustomInput__2vj6Y {
  height: 100%;
  width: 324px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.RoomOption_uploadCsv__ujPrx {
  background: rgba(0, 86, 150, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  width: 148.44px;
  height: 40px;
  color: #ffffff;
  padding: 0;
}
.RoomOption_uploadCsv__ujPrx label {
  width: 100%;
  height: 36px;
  line-height: 36px;
  vertical-align: middle;
  display: block;
}

.RoomOption_uploadCsv__ujPrx input {
  display: none;
}

.RoomOption_pOption__3-tDq {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}
.RoomOption_csvDetail__11O3k {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.RoomOption_gameQuest__Xr0u2 {
  display: flex;
  margin-left: 68px;
  width: 700px;
  margin-top: 24px;
  margin-bottom: 55px;
  justify-content: space-between;
  align-items: center;
}
.RoomOption_miniContainer__27LTX {
  display: flex;
  margin-left: 68px;
  width: 400px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}
.RoomOption_gameSetting__1hHxr {
  margin-top: 8px;
}
.RoomOption_inputSpeed__3sd-i {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  width: 95px;
  height: 34px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}
.RoomOption_centerGameSetOption__1l3xa {
  display: flex;
  width: 100px;
  justify-content: center;
}

.User_containerWrapper__2jO78 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.User_container__3inWl {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: 760px;
  height: 680px;
  background-color: #ffffff;
  padding: 55px;
  margin-top: 30px;
  box-shadow: 1px 5px 5px 1px #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.User_formMainContainer__EE6Vv {
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  gap: 25px;
  width: 500px;
}

.User_blockTitle__1E6iZ {
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  margin-left: -45px;
}

.User_labelInput__3SFKc {
  font-size: 18px;
  font-weight: 500;
}

.User_input__2jMOd {
  width: 243px;
  height: 33px;
  border-radius: 0;
  border: 2px solid #000000;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

input[type="email"]:disabled {
  background: #c4c4c4;
  color: #000000;
  text-decoration: underline;
}

.User_inputContainer__2YS1L {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.User_btnBlock__1Rw9e {
  display: flex;
  justify-content: flex-end;
}

.User_btnSave__18rxi {
  background-color: #80FF7C;
  width: 85px;
  height: 30px;
  cursor: pointer;
  border: 2px solid #000000;
}

.User_error__17bBO {
  position: absolute;
  left: 265px;
  top: 38px;
  color: #ff0000;
}

.User_passwordError__2-pts {
  position: absolute;
  left: 0px;
  white-space: nowrap;
  top: 38px;
  color: #ff0000;
}
.SelectList_labelList__2LO3- {
  width: 400px;
  position: relative;
}
.SelectList_select__KCVy- {
  z-index: 1;
  width: 243px;
  height: 33px;
  background: #ffffff;
  box-sizing: border-box;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-align: center;
}
.SelectList_btnList__qXPGI {
  z-index: 5;
  position: absolute;
  border: 2px solid black;
  width: 33px;
  height: 33px;
  background: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -33px;
  margin-left: 210px;
}
.SelectList_btnList__qXPGI img {
  width: 20px;
}

.SelectList_options__2p5RK {
  z-index: 3;
  position: absolute;
}
.SelectList_options__2p5RK div {
  display: flex;
  align-items: center;
  border: 1px solid black;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 243px;
  height: 33px;
  background: #ffffff;
  transition: 0.4s;
  justify-content: center;
}
.SelectList_options__2p5RK div:hover {
  background: #b9b9b9;
  transition: 0.4s;
}

* {
  box-sizing: border-box;
}

.resetPassword_containerWrapper__1ta62 {
  display: flex;
  justify-content: center;
}

.resetPassword_container__1DkIA {
  font-family: Roboto;
  width: 625px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
}

.resetPassword_blockTitle__3pMYQ {
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 110px;
}

.resetPassword_formMainContainer__PFesq {
  background-color: #202232;
  width: 100%;
  height: 220px;
  border-radius: 40px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 80px;
}

.resetPassword_inputContainer__3UUXE {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resetPassword_formTitle__SoHbm {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  margin-top: 15px;
}

.resetPassword_input__1iVob {
  width: 100%;
  height: 70px;
  font-size: 36px;
}

.resetPassword_btnSendEmail__3JX0- {
  width: 220px;
  height: 90px;
  background-color: #202232;
  border-radius: 18px;
  border: 0;
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  cursor: pointer;
}
.resetPassword_BtnReset__eFStU {
  width: 218px;
  height: 93.09px;

  background: #202232;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 53px;

  color: #ffffff;
}
.resetPassword_positionRightBtn__26Jkr {
  position: absolute;
  margin-left: 1050px;
  margin-top: 30px;
}

.resetPassword_error__3OWlN {
  width: 500px;
  margin-top: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #ac0505;
}
* {
  box-sizing: border-box;
}

.email_containerWrapper__3gEL1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.email_container__22me- {
  font-family: Roboto;
  width: 625px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
}

.email_mainBlock__g9mgL {
  width: 780px;
  height: 420px;
  background-color: #202232;
  border-radius: 40px;
  margin-bottom: 70px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email_mainIcon__1w08O {
  position: relative;
  width: 426px;
  height: 255px;
  left: 55px;
}

.email_mainBlockText__5Mhlj {
  color: #FFFFFF;
  font-size: 36px;
  font-weight: 500;
}

.email_btnMainPage__JZ8ls {
  width: 220px;
  height: 90px;
  background-color: #202232;
  border-radius: 18px;
  border: 0;
  color: #FFFFFF;
  font-size: 36px;
  font-weight: 700;
  cursor: pointer;
}
* {
  box-sizing: border-box;
}

.resetPassword_containerWrapper__cc4j6 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetPassword_container__2yrWe {
  font-family: Roboto;
  width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
}

.resetPassword_blockTitle__2q-iB {
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 50px;
}

.resetPassword_formMainContainer__w2pdD {
  background-color: #202232;
  width: 100%;
  height: 400px;
  border-radius: 40px;
  padding: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 80px;
}

.resetPassword_inputContainer__1bygB {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.resetPassword_label__11Kv7 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
}

.resetPassword_input__3hTvC {
  width: 565px;
  height: 70px;
  font-size: 36px;
}

.resetPassword_btnReset__2LGBJ {
  width: 220px;
  height: 90px;
  background-color: #202232;
  border-radius: 18px;
  border: 0;
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  cursor: pointer;
}

.resetPassword_inputWrapper__3RuEs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.resetPassword_icon__13Fay {
  width: 45px;
  height: 45px;
  margin: 20px;
  cursor: pointer;
}
.resetPassword_tooltipContainer__3B-5C {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.resetPassword_error__3vG6y {
  width: 500px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 10px;
  color: #ac0505;
}

.DashBoard_containerFlex__1lRVl {
  display: flex;
  justify-content: center;
  width: 100%;
}

.DashBoard_mainContainer__3QO0n {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: 35px;
  margin-top: 51px;
  padding-left: 34px;
  padding-top: 9px;
  padding-bottom: 50px;
  width: 974px;
}

.DashBoard_textMain__Ra3vL {
  border-bottom: 5px solid #ec1f25;
  color: #000000;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
}
.DashBoard_blockOption__1cRhH {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.DashBoard_blockForDate__32dmx {
  margin-bottom: 42px;
  margin-left: 68px;
}
.DashBoard_inputContainer__3MJBA {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  padding-bottom: 13px;
  width: 500px;
}
.DashBoard_inputDate__3uufo {
  border-radius: 26px;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: 52px;
  outline: none;
  padding-left: 24px;
  width: 316px;
}
.DashBoard_IconDate__q8w9h {
  margin-left: 267px;
  margin-top: 2px;
  position: absolute;
  width: 52px;
}
.DashBoard_blockCustomInput__3Q_Cx {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 324px;
}
.DashBoard_uploadCsv__2JduV {
  background: rgba(0, 86, 150, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  width: 148.44px;
}
.DashBoard_pOption___6ENW {
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.DashBoard_gameQuest__1Vcg2 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;
  margin-left: 68px;
  margin-top: 24px;
  width: 420px;
}
.DashBoard_miniContainer__32zD4 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  margin-left: 68px;
  width: 400px;
}
.DashBoard_gameSetting__2ji_e {
  margin-top: 8px;
}
.DashBoard_inputSpeed__3doq8 {
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #000000;
  box-sizing: border-box;
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: 34px;
  line-height: 28px;
  width: 95px;
}
.DashBoard_centerGameSetOption__3Xl1l {
  display: flex;
  justify-content: center;
  width: 100px;
}
/* ////// */
.DashBoard_btnChange__gZhgr {
  background: #ffcece;
  border-radius: 6px;
  color: #000000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  height: 47px;
  line-height: 21px;
  width: 107px;
}
.DashBoard_gameType__ta6sW {
  align-items: center;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 132px;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 48px;
  width: 500px;
  width: 630px;
}

.DashBoard_gameType__ta6sW img {
  margin: 0 30px;
}

.DashBoard_gameType__ta6sW p {
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
}
.DashBoard_roomDetails__2S8mr {
  margin-left: 51px;
  margin-top: 18px;
}
.DashBoard_roomDetails__2S8mr div p {
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.DashBoard_roomDetails__2S8mr div ul {
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-left: 40px;
  margin-top: 5px;
}
.DashBoard_roomName__3Meg4 {
  align-items: center;
  border: 1px solid #000000;
  box-sizing: border-box;
  height: 52px;
  text-align: center;

  margin-bottom: 12px;
  margin-left: 48px;
  margin-top: 25px;
  width: 575px;
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.DashBoard_roomDescription__Owwiy {
  border: 1px solid #000000;
  box-sizing: border-box;
  margin-bottom: 12px;
  margin-left: 48px;
  resize: vertical;
  max-height: 150px;
  margin-top: 25px;
  padding: 14px;
  width: 575px;
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.DashBoard_saving__xnfy- {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin-left: 5px;
}

.DashBoard_loader__3X1OY {
  margin-left: 30px;
}

.DashBoard_loader__3X1OY img {
  top: 10px;
}
.Loader_loader__qY97N {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  -webkit-animation: Loader_spin__3Xbvs 4s linear infinite;
  animation: Loader_spin__3Xbvs 2s linear infinite;
}

.Loader_loader__qY97N.Loader_sm__3ZaEz {
  width: 30px;
  height: 30px;
}
@-webkit-keyframes Loader_spin__3Xbvs {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes Loader_spin__3Xbvs {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}
.PopupMessage_Modal__3KNEq {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
}

.PopupMessage_Modal__3KNEq:target {
  display: flex;
  pointer-events: auto;
}
.PopupMessage_Modal_Body__5PbZv {
  position: relative;
  z-index: 50;
  top: 50px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 30px 15px;
  background: #FFFFFF;
  border: 4px solid #000000;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 36px;
  line-height: 42px;
  border-radius: 20px;
}
.PopupMessage_ModalFull__18juV {
  position: absolute;
  display: block;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.PopupMessage_successMessage__sh74g {
  color: #289600;
}

.PopupMessage_errorMessage__QgKRq {
  color: #FF0200;
}

.PopupMessage_lg__CgCMn {
  width: 80%;
}

.PopupMessage_md__2TrfW {
  width: 50%;
}

.PopupMessage_Modal_Body__5PbZv[animation="1"] {
  -webkit-animation: PopupMessage_modal-enter__eamAk 0.9s both;
          animation: PopupMessage_modal-enter__eamAk 0.9s both;
}

@-webkit-keyframes PopupMessage_modal-enter__eamAk {
  0% { top: 0; opacity: 0 }
  100% { top: 50px; opacity: 1 }
}

@keyframes PopupMessage_modal-enter__eamAk {
  0% { top: 0; opacity: 0 }
  100% { top: 50px; opacity: 1 }
}

.LogoutConfirm_Modal__3YYQB {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    background: rgba(0, 0, 0, 0.7);
}
.LogoutConfirm_Modal__3YYQB:target {
    display: flex;
    pointer-events: auto;
}
.LogoutConfirm_Modal_Body__3yCd- {
    position: relative;
    z-index: 200;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    padding: 15px;
    background: #fefefe;
    border: 6px solid #343434;
    box-sizing: border-box;
    border-radius: 15px;
    width: 876px;
    box-sizing: border-box;
    font-size: 2.4em;
}
.LogoutConfirm_ModalFull__2e5Tg {
    position: absolute;
    display: block;
    z-index: 90;
    width: 100%;
    height: 100%;
}
.LogoutConfirm_inputRoomName__IDFKZ {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 3px;
    height: 52px;
    width: 717px;
}
.LogoutConfirm_blockRemoveRoom__1c-bh input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    padding-left: 15px;
    color: #000000;
}
.LogoutConfirm_content__2BEca {
    font-family: Roboto;
    font-size: 26px;
    text-align: center;
    margin-bottom: 50px;
}
.LogoutConfirm_blockRemoveRoom__1c-bh p {
    margin-bottom: 20px;
}
.LogoutConfirm_btn_cancel__1yG_N {
    background: #f1f1f1;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    height: 74px;
    width: 218px;
    cursor: pointer;
}
.LogoutConfirm_btn_remove__1YE2Q {
    margin-left: 51px;
    margin-right: 31px;
    background: #A10000;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    height: 74px;
    width: 218px;
    cursor: pointer;
}
.LogoutConfirm_btnContainer__B0loU {
    margin-top: 47px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.LogoutConfirm_inputRoomName__IDFKZ {
    margin-bottom: 30px;
}
.LogoutConfirm_title__1W0_3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;

    color: #000000;
    margin-bottom: 50px;
}

.StudentPrivacyConfirm_Modal__3eVRD {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    background: rgba(0, 0, 0, 0.7);
}
.StudentPrivacyConfirm_Modal__3eVRD:target {
    display: flex;
    pointer-events: auto;
}
.StudentPrivacyConfirm_Modal_Body__1d8Lz {
    position: relative;
    z-index: 200;
    top: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    padding: 15px;
    background: #fefefe;
    border: 6px solid #343434;
    box-sizing: border-box;
    border-radius: 15px;
    width: 80%;
    box-sizing: border-box;
    font-size: 2.4em;
}
.StudentPrivacyConfirm_ModalFull__74hDS {
    position: absolute;
    display: block;
    z-index: 90;
    width: 100%;
    height: 100%;
}
.StudentPrivacyConfirm_inputRoomName__2f4vV {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 3px;
    height: 52px;
    width: 717px;
}
.StudentPrivacyConfirm_blockRemoveRoom__327pM input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    padding-left: 15px;
    color: #000000;
}
.StudentPrivacyConfirm_content__2mQxK {
    font-family: Roboto;
    font-size: 26px;
    text-align: left;
    margin-bottom: 50px;
}
.StudentPrivacyConfirm_blockRemoveRoom__327pM p {
    margin-bottom: 20px;
}
.StudentPrivacyConfirm_agreeCheckbox__LZVMF{
    font-family: Roboto;
    font-size: 26px;
    text-align: center;
    margin-bottom: 50px;
}
.StudentPrivacyConfirm_btn_cancel__PE2HL {
    background: #f1f1f1;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    height: 74px;
    width: 218px;
    cursor: pointer;
}
.StudentPrivacyConfirm_btn_accept__3A65l {
    margin-left: 51px;
    margin-right: 31px;
    background: #000000;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    height: 74px;
    width: 218px;
    cursor: pointer;
}
.StudentPrivacyConfirm_btn_accept_gray__3iTV2 {
    margin-left: 51px;
    margin-right: 31px;
    background: #999999;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    height: 74px;
    width: 218px;
    cursor: pointer;
}
.StudentPrivacyConfirm_btnContainer__1cPWf {
    margin-top: 47px;
    display: flex;
    justify-content: center;
    width: 100%;
}
.StudentPrivacyConfirm_inputRoomName__2f4vV {
    margin-bottom: 30px;
}
.StudentPrivacyConfirm_title__Th7zo {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;

    color: #000000;
    margin-bottom: 50px;
}

.StudentPrivacyConfirm_agreementText__21Ror {
    overflow:inherit;
    word-wrap: break-word;
    font-family: Roboto;
    color: #000000;
    text-align: left;
    margin-left: 10px;
}
  
.StudentPrivacyConfirm_agreementRegion__3fkHU{
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    justify-content: left;
    align-items: left;
}
.StudentPrivacyConfirm_agreementArea__nbrgr{
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 10px;
    color: #000000;
    margin-left: 20%;
    margin-right: 20%;
    justify-content: left;
    align-items: left;
}
html, body {
  font-family: "Roboto", sans-serif;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

